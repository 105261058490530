
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  nextTick,
  onMounted,
  unref
} from 'vue';
import { ElForm, ElMessage } from 'element-plus';
import { cloneDeep } from 'lodash';
import {
  getArticles,
  getPageviews,
  createArticle,
  updateArticle,
  defaultArticleModel
} from '@/apis/articles';
import { ArticleModel } from '@/model/articleModel';

import { exportJson2Excel } from '@/utils/excel';
import { formatJson } from '@/utils';
import { useI18n } from 'vue-i18n';
// import Pagination from '@/components/Pagination/index.vue'
export default defineComponent({
  components: {
    // Pagination
  },
  setup() {
    const { t } = useI18n();
    const calendarTypeOptions = [
      { key: 'CN', displayName: 'China' },
      { key: 'US', displayName: 'USA' },
      { key: 'JP', displayName: 'Japan' },
      { key: 'EU', displayName: 'Eurozone' }
    ];

    const calendarTypeKeyValue = calendarTypeOptions.reduce(
      (acc: { [key: string]: string }, cur) => {
        acc[cur.key] = cur.displayName;
        return acc;
      },
      {}
    ) as { [key: string]: string };
    const dataForm = ref(ElForm);
    const dataMap = reactive({
      tableKey: 0,
      list: Array<ArticleModel>(),
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id'
      },

      importanceOptions: [1, 2, 3],
      calendarTypeOptions: calendarTypeOptions,
      sortOptions: [
        { label: 'ID Ascending', key: '+id' },
        { label: 'ID Descending', key: '-id' }
      ],

      statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: 'Edit',
        create: 'Create'
      },

      dialogPageviewsVisible: false,
      pageviewsData: [],
      rules: {
        type: [
          { required: true, message: 'type is required', trigger: 'change' }
        ],
        timestamp: [
          {
            required: true,
            message: 'timestamp is required',
            trigger: 'change'
          }
        ],
        title: [
          { required: true, message: 'title is required', trigger: 'blur' }
        ]
      },
      downloadLoading: false,
      tempArticleModel: defaultArticleModel,
      handleCurrentChange(page?: any) {
        dataMap.getList(page);
      },
      handleSizeChange(val: any) {
        dataMap.getList(null, null, val);
      },
      async getList(page?: any, total?: any, limit?: any) {
        if (page) {
          dataMap.listQuery.page = page;
        }
        if (limit) {
          dataMap.listQuery.limit = limit;
        }
        console.log(total);
        dataMap.listLoading = true;
        const data = await getArticles(dataMap.listQuery);
        dataMap.list = data?.data.items ?? [];
        dataMap.total = data?.data.total ?? 0;

        // Just to simulate the time of the request
        setTimeout(() => {
          dataMap.listLoading = false;
        }, 0.5 * 1000);
      },
      handleFilter() {
        dataMap.listQuery.page = 1;
        dataMap.getList();
      },
      handleModifyStatus(row: any, status: string) {
        ElMessage.success({
          message: '操作成功',
          type: 'success'
        });
        row.status = status;
      },
      sortChange(data: any) {
        const { prop, order } = data;
        if (prop === 'id') {
          dataMap.sortByID(order);
        }
      },
      sortByID(order: string) {
        if (order === 'ascending') {
          dataMap.listQuery.sort = '+id';
        } else {
          dataMap.listQuery.sort = '-id';
        }
        dataMap.handleFilter();
      },
      getSortClass(key: string) {
        const sort = dataMap.listQuery.sort;
        return sort === `+${key}` ? 'ascending' : 'descending';
      },
      resetTempArticleModel() {
        dataMap.tempArticleModel = cloneDeep(defaultArticleModel);
      },
      handleCreate() {
        console.log('添加了');
        dataMap.resetTempArticleModel();
        dataMap.dialogStatus = 'create';
        dataMap.dialogFormVisible = true;
        nextTick(() => {
          (dataForm.value as typeof ElForm).clearValidate();
        });
      },
      createData() {
        const form = unref(dataForm);
        form.validate(async(valid: any) => {
          if (valid) {
            const ArticleModel = dataMap.tempArticleModel;
            ArticleModel.id = Math.round(Math.random() * 100) + 1024; // mock a id
            ArticleModel.author = 'RCYJ_Scy';
            const addData = await createArticle(ArticleModel);

            if (addData?.data.id) {
              alert(addData.data.id);
              console.log(addData);
              dataMap.list.unshift(addData.data);
            }

            dataMap.dialogFormVisible = false;
            ElMessage.success({
              message: '创建成功',
              type: 'success',
              duration: 2000
            });
          }
        });
      },

      handleUpdate(row: any) {
        dataMap.tempArticleModel = Object.assign({}, row);
        dataMap.tempArticleModel.timestamp = +new Date(
          dataMap.tempArticleModel.timestamp
        );
        dataMap.dialogStatus = 'update';
        dataMap.dialogFormVisible = true;
        nextTick(() => {
          (dataForm.value as typeof ElForm).clearValidate();
        });
      },
      updateData() {
        const form = unref(dataForm);
        form.validate(async(valid: any) => {
          if (valid) {
            const tempData = Object.assign({}, dataMap.tempArticleModel);
            tempData.timestamp = +new Date(tempData.timestamp); // change Thu Nov 30 2017 16:41:05 GMT+0800 (CST) to 1512031311464
            console.log(tempData);
            const data = await updateArticle(tempData);

            console.log(data, '-----------------');
            if (data) {
              const index = dataMap.list.findIndex(
                (v) => v.id === data.data.id
              );
              dataMap.list.splice(index, 1, data.data);
            }

            dataMap.dialogFormVisible = false;
            ElMessage.success({
              message: '更新成功',
              type: 'success',
              duration: 2000
            });
          }
        });
      },
      handleDelete(row: any, index: number) {
        ElMessage.success({
          message: 'Delete Successfully',
          type: 'success',
          duration: 2000
        });
        dataMap.list.splice(index, 1);
      },
      async handleGetPageviews(pageviews: string) {
        const data = await getPageviews({ pageviews });
        dataMap.pageviewsData = data?.data.pageviews;
        dataMap.dialogPageviewsVisible = true;
      },
      handleDownload() {
        dataMap.downloadLoading = true;
        const tHeader = ['timestamp', 'title', 'type', 'importance', 'status'];
        const filterVal = [
          'timestamp',
          'title',
          'type',
          'importance',
          'status'
        ];
        const data = formatJson(filterVal, dataMap.list);
        exportJson2Excel(tHeader, data, 'table-list');
        dataMap.downloadLoading = false;
      },
      typeFilter: (type: string) => {
        return calendarTypeKeyValue[type];
      }
    });
    onMounted(() => {
      console.log(typeof ElForm);
      dataMap.getList(null, null, 20);
    });
    return { t, ...toRefs(dataMap), dataForm };
  }
});
